/**
 * Copyright © Magento, Inc. All rights reserved.
 * See COPYING.txt for license details.
 */

define([
    'jquery',
    'mage/smart-keyboard-handler',
    'stickyApply',
    'lazysizes',
    'aos',
    'mage/mage',
    'mage/ie-class-fixer',
    'domReady!'
], function ($, keyboardHandler, stickyApply, lazysizes, AOS) {
    'use strict';
    
    if ($('body').hasClass('checkout-cart-index')) {
        if ($('#co-shipping-method-form .fieldset.rates').length > 0 &&
            $('#co-shipping-method-form .fieldset.rates :checked').length === 0
        ) {
            $('#block-shipping').on('collapsiblecreate', function () {
                $('#block-shipping').collapsible('forceActivate');
            });
        }
    }

    $('.cart-summary').mage('sticky', {
        container: '#maincontent',
        spacingTop: 150
    });
   
    $('.page-header').mage('stickyAddToCart');

    $('.category-main-root-filter').mage('stickyAddToCart');

    $('img[data-hover-src]').mage('imageHoverFocus');
        
    $('li .item.close').click(
        () => window.history.back()
    );
    

    if($('[data-aos]').length){
        var efeito = $('[data-aos]').attr("data-aos");
        if(efeito != "none") {
            AOS.init();
            $.fn.isInViewport = function() {
                var elementTop = $(this).offset().top;
                var elementBottom = elementTop + $(this).outerHeight();
               
                var viewportTop = $(window).scrollTop();
                var viewportBottom = viewportTop + $(window).height();
               
                return elementBottom > viewportTop && elementTop < viewportBottom;
            };
            $(window).on('resize scroll', function() {
                if ($('[data-aos]').isInViewport()) {
                    AOS.refresh();
                }
            });
        }
    }

    $('[data-sytle-padding]').mage('imageSytle');

    window.addEventListener('beforeunload', (event) => {
        $('[data-role="main-css-loader"]').show();
        setTimeout(function(){ event }, 0);
        setTimeout(function(){ $('[data-role="main-css-loader"]').hide(); }, 3000);
    });
  
    keyboardHandler.apply();
});