/**
 * Copyright © Magento, Inc. All rights reserved.
 * See COPYING.txt for license details.
 */

define([
    'jquery',
    'jquery-ui-modules/widget',
], function ($) {
    'use strict';
   
    $.widget('mage.stickyAddToCart', {
       options: {
            stickyClass: '_sticky',
            sitckyClassExclude: 'active',
            wrapper: '.page-wrapper'
        },

        _create: function () {
            if (!this.options.disabled) {
                this._initializeSticky();
            }
        },

        _initializeSticky: function() {

            const self = this;
            const item = this.element;

            const wrapper = $(this.options.wrapper);
           
            let position = item.position();
            let stickyDummyHeight = position.top + item.height();

            let scrollPosition;
            let lastScrollTop = 0;
            let applySpacing;
            if (window.innerWidth) {
                window.addEventListener("scroll", function(){ 
                   var st = window.pageYOffset || document.documentElement.scrollTop;
                   if (st > lastScrollTop){
                        applySpacing = 0;
                   } else {
                        applySpacing = 1;
                   }
                   lastScrollTop = st <= 0 ? 0 : st;
                }, false);
                $(window).scroll(function () {
                    scrollPosition = $(window).scrollTop();
                    if (scrollPosition > stickyDummyHeight) {
                        self._applySpacingTop(applySpacing);
                        self._enableSticky(item, stickyDummyHeight);
                    } else {

                        if(item.attr('class') != "page-header _sticky" || scrollPosition < 20){
                           self._disableSticky(item);
                        }
                    }
                });
            }
        },

        _applySpacingTop: function(apply){
           
            var iniEl = $('.product-addto-cart-sticky');
            if($('.category-main-root-filter').length){
                var iniEl = $('.category-main-root-filter');
            }
            let headerEl =  $('.page-header');
            if(iniEl.length){
                if(apply && iniEl.hasClass(this.options.stickyClass)){
                    iniEl.css({'top':headerEl.height()});
                    headerEl.addClass('_stick-new-z-index');
                } else {
                    if(!headerEl.hasClass(this.options.sitckyClassExclude)) {
                         headerEl.removeClass('_stick-new-z-index');
                         iniEl.css({'top':0});
                    }
                }
            }
            
        },


        _enableSticky: function(el, height) {
            if (!el.hasClass(this.options.stickyClass)) {
                el.addClass(this.options.stickyClass);
            }
        },

        _disableSticky: function(el) {
            if (el.hasClass(this.options.stickyClass)) {
                el.removeClass(this.options.stickyClass);
            }
        }
    });

    return $.mage.stickyAddToCart;
});
